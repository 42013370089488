import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AvatarDefault from "@assets/images/Frame 3019.png";
import TableComponent from "@components/TableComponent";
import { Button, ConfigProvider, Empty, Modal, Space, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { authGetData } from "@utils/request";
import { Layout } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { mapLanguageToCulture } from "@utils/function";
const { Footer } = Layout;

const UserItem = ({
  users,
  handleDelete,
  filterConditions,
  onChangePagination,
  setVisible,
  visible,
}) => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const culture = mapLanguageToCulture();
  const [deleteId, setDeleteId] = useState();
  const [deleteuser, setdeleteuser] = useState();
  const [loading, setLoading] = useState(false);
  const Link = styled.a``;

  const navigate = useNavigate();

  const showDeleteConfirm = (id) => {
    authGetData({
      url: `api/v1/admin/user/${id}?culture=${culture}`,
      method: "GET",
      setLoading,
      onSuccess: (res) => {
        setdeleteuser(res.data.email);
      }
    });
    setDeleteId(id);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: t('No.'),
      dataIndex: "number",
      width: "4%",
      render: (record, item) => (
        <span>{item.number + 1}</span>
      ),
    },
    {
      title: t('Full Name'),
      dataIndex: "fullName",
      ellipsis: true,
      align: "left",
      render: (record, item) => (
        <p
          style={{ color: "#1890ff", cursor: "pointer" }}
          onClick={() => {
            navigate(`${item.id}`);
          }}
        >
          {record}
        </p>
      ),
      width: "22%",
    },
    {
      title: t('Avatar'),
      render: (record) =>
        record.avatarUrl ? (
          <img
            src={record.avatarUrl}
            alt="Avatar"
            style={{ height: "40px", width: "40px", objectFit: "cover" }}
          />
        ) : (
          <img
            src={AvatarDefault}
            alt="AvatarDefault"
            style={{ height: "40px", width: "40px", objectFit: "cover" }}
          />
        ),
        width: "12%",
      className: 'min-width-120'
    },
    {
      title: t('Email'),
      dataIndex: "email",
      align: "left",
      width: "38%",
    },
    {
      title: t('Role'),
      align: "left",
      render: (record, item) => (
        <span>{item.isAdmin ? t('Admin') : t('Users')}</span>      
      ),
      width: "12%",
      className: 'min-width-120'
    },
    {
      title: t('Custom...'),
      render: (record) => (
        < Space size="middle" >
          <EditOutlined
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              navigate(`${record.id}`);
            }}
          />
          {
            record.email !== "admin@3simedia.vn" && (
              <DeleteOutlined
                style={{ color: "red", marginLeft: 12, cursor: "pointer" }}
                onClick={() => showDeleteConfirm(record.id)}
              />
            )
          }
        </Space >
      ),
      width: "12%",
    },
  ];

  return (
    <Spin spinning={loading}>
      <ConfigProvider
        renderEmpty={() => <Empty description={t('No Records Found')} />}
      >
        <TableComponent
          rowKey={(record) => record.id}
          dataSource={users}
          columns={columns}
          totalData={users.length}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
        />
        <Modal
          key="user-item"
          title={t('Please confirm')}
          open={visible}
          onOk={() => handleDelete(deleteId)}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t('Cancel')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => handleDelete(deleteId)}
            >
              {t('Agree')}
            </Button>,
          ]}
        >
          <p>{t('Are you sure you want to delete user')} <span>{deleteuser}</span>?</p>
        </Modal>
      </ConfigProvider>
    </Spin>
  );
};

export default UserItem;
