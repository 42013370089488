import { BackTop, Tabs } from "antd";
import React from "react";
import { IoChevronUpOutline } from "react-icons/io5";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import styled from "styled-components";
import HeaderMain from "./HeaderMain";
import ArtistsPage from "@pages/LandingPageScreen/ArtistsPage";
import background from './image/background.png';
import ReleasePage from "@pages/LandingPageScreen/ReleasePage";
import TrackPage from "@pages/LandingPageScreen/TrackPage";
import CountryPage from "@pages/LandingPageScreen/CountryPage";
import PlatformPage from "@pages/LandingPageScreen/PlatformPage";
import OverViewLandingPage from "@pages/LandingPageScreen/OverViewPage";
import { useTranslation } from "react-i18next";

export default function LandingPageComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const imageUrl = background
  const { t } = useTranslation();

  const activeKey = location.pathname.split('/')[2] || 'overview';

  const items = [
    {
      key: "overview",
      label: t("Dashboard"),
    },
    {
      key: "artist",
      label: t("Artists"),
    },
    {
      key: "release",
      label: t("Releases"),
    },
    {
      key: "track",
      label: t("Tracks"),
    },
    {
      key: "country",
      label: t("Country"),
    },
    {
      key: "platform",
      label: t("Stores"),
    },
  ];

  const handleChangeTab = (key) => {
    navigate(`${key}`);
  }

  return (
    <>
      <StyledHeader>
        <HeaderMain />
      </StyledHeader>
      <div style={{ marginTop: "53px" }}>
        <Styled imageUrl={imageUrl}>
          <div className="background">
            <div className="overlay-style"></div>
            <div className="tabs-style">
              <Tabs
                defaultActiveKey="overview"
                items={items}
                onChange={handleChangeTab}
                activeKey={activeKey}
              />
            </div>
            <Routes>
              <Route path="/overview" element={<OverViewLandingPage />} />
              <Route path="/artist" element={<ArtistsPage />} />
              <Route path="/release" element={<ReleasePage />} />
              <Route path="/track" element={<TrackPage />} />
              <Route path="/country" element={<CountryPage />} />
              <Route path="/platform" element={<PlatformPage />} />
            </Routes>
          </div>
        </Styled>
        <Footer />
      </div>

      <BackTop style={{ zIndex: "10000" }}>
        <IoChevronUpOutline />
      </BackTop>
    </>
  );
}

const Styled = styled.div`
  .ant-layout-content {
    background-color: rgb(229, 229, 229);
    margin-left: 210px;
    height: 100%;
  }
  .ant-layout-content .ant-layout-content {
    margin-left: 0;
  }

  .ant-tabs-tab {
    font-size: 20px;
    color: rgb(111 109 109);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
    text-shadow: 0px 0px 1px #000;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: unset !important;
  }

  .background {
    padding: 0 175px 20px 175px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    position: relative;
    z-index: 0;
    background-image: url(${props => props.imageUrl});
  }

  .overlay-style {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background-color: rgba(0, 0, 0, 0.4);
    // z-index: -1;
  };

  .tabs-style {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1100px) {
    .background {
      padding: unset;
    }
  }

  @media (max-width: 980px) {
    .ant-layout-content {
      margin-left: 160px;
    }

    .background {
      padding: unset;
    }
  }
  
  @media (max-width: 700px) {
    .tabs-style {
      justify-content: start;
      margin: 0 10px;

      .ant-tabs {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; /* Đảm bảo cuộn mượt mà trên iOS */
      }
    }
  }
`;

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`