import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import SelectionLabels from "@components/SelectLabels";
import {
  buildQueryString,
  disabledDateMonth,
  getErrorForm,
  handlePagination,
  mapLanguageToCulture,
  removeAccents,
} from "@utils/function";
import {
  authDeleteData,
  authGetData,
  authPostData,
  authPutData,
} from "@utils/request";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Spin,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { setArtistShareRate } from "../../../redux/Date/DateReducer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";
const { Option } = Select;

function SettingShareRateMain() {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const [loading, setLoading] = useState(false);
  const [artists, setArtists] = useState([]);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [itemEdit, setItemEdit] = useState();
  const [id, setId] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const artistShareRate = useSelector((state) => state.date.artistShareRate);
  const [visible, setVisible] = useState(false);
  const [edittingShareRate, setEdittingShareRate] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [isUsed, setisUsed] = useState();
  const navigate = useNavigate();
  const [checkPopup, setCheckPopup] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    layout: "vertical",
  };
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const checkShareRate = () => {
    authGetData({
      url: `api/v1/overview/check-share-rate?culture=${culture}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          dispatch(setArtistShareRate(res.data));
        }
      },
    });
  };

  const ModalWarningChange = () =>
    Modal.confirm({
      centered: true,
      width: 600,
      title: t("Save the change?"),
      content: t(
        "If you move/close tab without saving, the changes you made earlier will be lost"
      ),
      onOk() {
        setEdit(false);
        setVisible(false);
        setEdittingShareRate(false);
        setId(null);
        setItemEdit(null);
        setisUsed(false);
        setCheckPopup(false);
        form.resetFields();
      },
      onCancel() {
        navigate("/admin/setting-share-rate");
      },
      okText: t("Agree"),
      okButtonProps: { type: "primary" },
      cancelText: t("Cancel"),
    });

  const [query, setQuery] = useState({
    artistId: searchParams?.get("artistId") || "",
    status: searchParams?.get("status") || "2",
    pageSize: searchParams.get("pageSize") || filterConditions.pageSize,
    pageIndex: searchParams.get("pageIndex") || filterConditions.pageIndex,
  });

  const columns = [
    {
      title: t("No."),
      dataIndex: "key",
      align: "center",
      width: "4%",
    },
    {
      title: t("Labels"),
      dataIndex: "artistName",
      align: "left",
      width: "18%",
      className: "min-width-140",
    },
    {
      title: t("Label ( % )"),
      dataIndex: "artistRate",
      align: "center",
      width: "18%",
      className: "min-width-120",
    },
    {
      title: t("3S Media ( % )"),
      dataIndex: "MediaRate",
      align: "center",
      width: "14%",
      className: "min-width-120",
    },

    {
      title: t("Start month"),
      dataIndex: "startDate",
      align: "center",
      width: "13%",
      className: "min-width-120",
    },
    {
      title: t("End month"),
      dataIndex: "endDate",
      align: "center",
      width: "13%",
      className: "min-width-120",
    },
    {
      title: t("Status"),
      align: "center",
      render: (record, item) =>
        !item.isUsed ? (
          <span style={{ color: "blue" }}>{t("Not used")}</span>
        ) : (
          <span style={{ color: "red" }}>{t("In use")}</span>
        ),
      width: "12%",
      className: "min-width-120",
    },
    {
      title: t("Custom..."),
      align: "center",
      render: (record, item) =>
        !item.isUsed ? (
          <Space size="middle">
            <EditOutlined
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onEdit(item)}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12, cursor: "pointer" }}
              onClick={() => showDeleteConfirm(record.id)}
            />
          </Space>
        ) : (
          <Space size="middle">
            <EditOutlined
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onEdit(item)}
            />
            <DeleteOutlined style={{ color: "gray", marginLeft: 12 }} />
          </Space>
        ),
      width: "10%",
    },
  ];

  const dataSource =
    data.length > 0 &&
    data.map((item) => {
      const objStartDate = new Date(item.startDate);
      const objEndDate = new Date(item.endDate);

      const convertStartDate =
        typeof objStartDate === "object" &&
        `${moment(objStartDate).format("MM/YYYY")}`;

      const convertEndDate =
        typeof objEndDate === "object" &&
        `${moment(objEndDate).format("MM/YYYY")}`;

      return {
        key: item.key,
        MediaRate: `${Number((100 - item.rate * 100).toFixed(2))}`,
        artistRate: `${Number((item.rate * 100).toFixed(2))}`,
        startDate: `${convertStartDate}`,
        endDate: `${convertEndDate}`,
        artistName: item.userName,
        id: item.id,
        start: item.startDate,
        end: item.endDate,
        artistId: item.userId,
        isUsed: item.isUsed,
      };
    });

  useEffect(() => {
    if (
      artistShareRate &&
      artistShareRate.artists &&
      artistShareRate.artists.length > 0
    ) {
      form.setFieldsValue({
        userIds: artistShareRate.artists.map((artist) => {
          return artist.id;
        }),
      });
    }

    getArtistsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchParams(query);
    getTableData(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, culture]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);

    setQuery((prev) => {
      return { ...prev, pageIndex: paging.current, pageSize: paging.pageSize };
    });
  };

  const getTableData = useCallback(
    (queryObj) => {
      const queryCulture = { ...queryObj, culture: culture };

      const queryString = buildQueryString(queryCulture);
      const url = queryString
        ? "api/v1/admin/setting/share-rate?" + queryString
        : "api/v1/admin/setting/share-rate";

      authGetData({
        url,
        method: "GET",
        setLoading,
        onSuccess: (res) => {
          setData(
            res.data.map((e, index) => {
              return {
                ...e,
                key:
                  (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                  (index + 1),
              };
            })
          );

          setTotalData(res.paging.totalCount);
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [culture]
  );

  const getArtistsData = () => {
    authGetData({
      url: `api/v1/overview/filter/labelnames`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 201) {
          setArtists(res.data);
        }
      },
    });
  };

  const onEdit = (item) => {
    setCheckPopup(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setEdit(true);
    setEdittingShareRate(true);
    setId(item.id);
    setItemEdit(item);
    setisUsed(item.isUsed);
    const startDate = new Date(item.start);
    const endDate = new Date(item.end);
    form.setFieldsValue({
      userIds: [item.artistId],
      rate: item.artistRate,
      startDate: item.start ? moment(startDate) : null,
      endDate: item.end ? moment(endDate) : null,
    });
  };

  const onCancelEdit = () => {
    if (checkPopup === true) {
      ModalWarningChange();
    }
  };

  const handleDelete = (id) => {
    authDeleteData({
      url: `api/v1/admin/setting/share-rate/${id}?culture=${culture}`,
      setLoading: setTableLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setData((prev) => prev.filter((item) => item.id !== id));
          setVisible(false);
          notification.open({
            message: t("Success"),
            description: res.message,
            icon: <SmileOutlined style={{ color: "#ff1f1f" }} />,
          });

          checkShareRate();
        }
      },
    });
  };

  const handleCancel = () => {
    setVisible(false);
    ModalWarningChange();
  };

  const showDeleteConfirm = (id) => {
    setDeleteId(id);
    setVisible(true);
  };

  const handleSubmit = (values) => {
    setCheckPopup(false);
    const { rate, userIds, startDate, endDate } = values;

    if (edit) {
      const startDateMonthAndYear = startDate.format("MM/YYYY");
      const endDateMonthAndYear = endDate.format("MM/YYYY");
      const editFormData = {
        rate: Number(values.rate) / 100,
        startDate: startDateMonthAndYear,
        endDate: endDateMonthAndYear,
        id: id,
        userId: userIds[0],
      };

      authPutData({
        url: `api/v1/admin/setting/share-rate?culture=${culture}`,
        method: "PUT",
        payload: editFormData,
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            getTableData(query);

            notification.open({
              message: t("Success"),
              description: res.message,
              icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            checkShareRate();

            setItemEdit(null);
            setEdit(false);
            setEdittingShareRate(false);

            form.resetFields();
          } else {
            getErrorForm(res, form);
          }
        },
      });

      return;
    }

    const createFormData = {
      userIds,
      ...(rate && { rate: Number(rate) / 100 }),
      ...(startDate && {
        startDate:
          startDate?._d.toISOString().substring(5, 7) +
          "/" +
          startDate?._d.toISOString().substring(0, 4),
      }),
      ...(endDate && {
        endDate:
          endDate?._d.toISOString().substring(5, 7) +
          "/" +
          endDate?._d.toISOString().substring(0, 4),
      }),
    };
    authPostData({
      url: `api/v1/admin/setting/share-rate?culture=${culture}`,
      method: "POST",
      payload: createFormData,
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getTableData(query);

          notification.open({
            message: t("Success"),
            description: t("Add successfully"),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          checkShareRate();

          setItemEdit(null);
          setEdit(false);
          setEdittingShareRate(false);

          form.resetFields();
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  const handleChangeFields = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
  };

  const customFormat = (value) => `${moment(value).format("MM/YYYY")}`;

  const afterClose = () => {
    onCancelEdit();
  };

  return (
    <Spin spinning={loading}>
      <ContentStyled>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Revenue Split")}</div>
              <button
                className="btn primary-btn"
                onClick={() => {
                  setCheckPopup(true);
                  setEdittingShareRate(true);
                  setisUsed(false);
                }}
              >
                {t("Add")} <PlusOutlined />
              </button>
            </div>
            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  rowKey="key"
                  dataSource={dataSource}
                  columns={columns}
                  totalData={totalData}
                  loading={tableLoading}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                />
              </ConfigProvider>
            </div>

            <Modal
              key="confirm-share-rate-edit"
              title={t("Please confirm")}
              open={visible}
              onOk={() => handleDelete(deleteId)}
              onCancel={handleCancel}
              footer={[
                <Button
                  type="outline"
                  className="btn outline-btn"
                  key="back"
                  onClick={handleCancel}
                >
                  {t("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  className="btn primary-btn"
                  onClick={() => handleDelete(deleteId)}
                >
                  {t("Agree")}
                </Button>,
              ]}
            >
              <p>{t("Are you sure you want to delete ?")}</p>
            </Modal>

            {edittingShareRate && (
              <Modal
                key="update-share-rate"
                title={
                  edit
                    ? t("Edit revenue configuration")
                    : t("New Revenue Split")
                }
                open={edittingShareRate}
                maskClosable={true}
                onCancel={handleCancel}
                afterClose={afterClose}
                footer={null}
              >
                <FormStyled>
                  <Form
                    {...layout}
                    onFinish={handleSubmit}
                    form={form}
                    initialValues={{
                      userIds: [],
                      rate: null,
                      startDate: null,
                      endDate: null,
                    }}
                    onValuesChange={handleChangeFields}
                  >
                    <Form.Item
                      label={t("Labels")}
                      name="userIds"
                      className="first-input-field"
                      rules={[
                        {
                          required: true,
                          message: t("Label is required"),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("All")}
                        mode="multiple"
                        maxTagCount="responsive"
                        showSearch
                        showArrow={true}
                        allowClear={true}
                        loading={loading}
                        disabled={edit}
                        style={{ width: "100%" }}
                        filterOption={(input, option) => {
                          if (option && option.children) {
                            return (
                              removeAccents(option.children)
                                .toLowerCase()
                                .indexOf(removeAccents(input).toLowerCase()) >=
                              0
                            );
                          }
                        }}
                      >
                        {artists?.length
                          ? artists.map((item) => (
                              <Option key={item.userId} value={item.userId}>
                                {item.labelName}
                              </Option>
                            ))
                          : undefined}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={t("Label Percentage")}
                      tooltip={itemEdit && `${itemEdit.artistName}`}
                      rules={[
                        {
                          required: true,
                          message: t("Percentage of Business unit is required"),
                        },
                      ]}
                      name="rate"
                    >
                      <Input
                        type="number"
                        min={0}
                        suffix="%"
                        max={100}
                        disabled={isUsed}
                      />
                    </Form.Item>

                    <Form.Item
                      label={t("Start month")}
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: t("Start month is required"),
                        },
                      ]}
                    >
                      <DatePicker
                        locale={i18n.language === "vi" ? localeVi : localeEn}
                        placeholder={t("Select month")}
                        format={customFormat}
                        disabled={isUsed}
                        picker="month"
                        disabledDate={disabledDateMonth}
                      />
                    </Form.Item>

                    <Form.Item
                      label={t("End month")}
                      name="endDate"
                      rules={[
                        {
                          required: true,
                          message: t("End month is required"),
                        },
                      ]}
                    >
                      <DatePicker
                        locale={i18n.language === "vi" ? localeVi : localeEn}
                        placeholder={t("Select month")}
                        format={customFormat}
                        picker="month"
                        disabledDate={disabledDateMonth}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div className="btn-modal">
                        <Button onClick={handleCancel}>{t("Cancel")}</Button>
                        <Button type="primary" htmlType="submit">
                          {edit ? t("Update") : t("Add")}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </FormStyled>
              </Modal>
            )}
          </div>
        </div>
      </ContentStyled>
    </Spin>
  );
}

const ContentStyled = styled.div`
  .content {
    .ant-form-item {
      justify-content: center;
    }

    .ant-form-item:last-child {
      .ant-form-item-control-input-content {
        text-align: left;
      }
    }
  }
`;

const FormStyled = styled.div`
  .btn-modal {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    gap: 10px;
  }
`;

export default SettingShareRateMain;
