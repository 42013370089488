import { postData } from "@utils/request";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BASE_API_URL, } from "@utils/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import CartItemComponent from "../CardItemComponent/CardItem";

const payloaded = {
    searchTerm: "",
    pageSize: 9999,
    pageIndex: 1,
};

const PlatformPage = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        postData({
            url: BASE_API_URL + `api/mobile/v1/platform`,
            method: 'POST',
            payload: payloaded,
            setLoading: setLoading,
            onSuccess: (res) => {
                setData(res.data);
            },
        });
    }, [])

    return (
        <ContentStyled >
            <ContainerList>
                <strong style={{ fontSize: "30px", display: "flex", justifyContent: "center", textAlign: "center" }}>{t('Listens by stores')}</strong>
                {
                    loading
                        ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: "10px",
                        }}>
                            <Spin indicator={<LoadingOutlined spin />} />
                        </div>
                        :
                        <div>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <CartItemComponent
                                            isMiniIcon={false}
                                            index={index}
                                            key={item.id}
                                            item={item}
                                            name={item.platformName}
                                            totalView={item.totalView}
                                        />
                                    )
                                })
                            }
                        </div>
                }
            </ContainerList>
        </ContentStyled >
    );
};

export default PlatformPage;

const ContentStyled = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  .content {
    background-color: #fdfdfd;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 0px 20px;
    .ant-tabs-tab-btn {
      font-weight: 500 !important;
    }
  }
  .ant-tabs-content-holder {
    padding: 0px 20px !important;
  }
  button {
    margin-bottom: 20px;
  }

  @media (max-width: 980px) {
    padding: 0 20px 20px 20px;
  }

  @media (max-width: 480px) {
     padding: unset;
  }
`;

const ContainerList = styled.div`
    width: 750px;
    padding: 10px;
    min-height: 88vh;
    border-radius: 10px;

    .list-data {
        margin-top: 10px;
        overflow: hidden scroll;
        scrollbar-width: none;
        max-height: 79vh;
    }
`