import { SmileOutlined } from "@ant-design/icons";
import TableComponent from "@components/TableComponent";
import {
  handlePagination,
  mapCurrencyToCulture,
  mapLanguageToCulture,
  parseParams,
} from "@utils/function";
import { authPostData } from "@utils/request";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Empty,
  Form,
  Modal,
  notification,
  Space,
} from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import PaymentFilter from "./Components/PaymentFilter";
import FormItem from "antd/es/form/FormItem";
import { useTranslation } from "react-i18next";

const PaymentMain = () => {
  const { t } = useTranslation();
  const culture = mapLanguageToCulture();
  const currency = mapCurrencyToCulture();
  const [form] = Form.useForm();
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
    reportStatus: 2,
    ...parseParams(location.search),
  });
  const [total, setTotal] = useState();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [labelPay, setLabelPay] = useState("");
  const [totalPay, setTotalPay] = useState();
  const [realtotalPay, setrealTotalPay] = useState();
  const [payId, setPayId] = useState("");
  const [payStartDate, setPayStartDate] = useState();
  const [payEndDate, setPayEndDate] = useState();
  const userData = useSelector((state) => state.auth.user);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [, setSearchParams] = useSearchParams();

  const [totalRevenue, setTotalRevenue] = useState();
  const [total3SRevenue, setTotal3SRevenue] = useState();
  const [totalArtistsPaidRevenue, setTotalArtistsPaidRevenue] = useState();
  const [totalArtistsUnPaidRevenue, setTotalArtistsUnPaidRevenue] = useState();
  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };
  const removeUndefinedAttribute = (obj) => {
    const params = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined) {
        params[key] = obj[key];
      }
      return {};
    });
    return params;
  };
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  // eslint-disable-next-line prefer-const
  let columns = [
    {
      title: t("No."),
      dataIndex: "no",
      width: "3%",
    },
    {
      title: t("Labels"),
      dataIndex: "label",
      align: "left",
      width: filterConditions.reportStatus !== 1 ? "16%" : "14%",
      className: "min-width-160",
    },
    {
      title: t("Tracks"),
      dataIndex: "trackTitle",
      align: "left",
      width: filterConditions.reportStatus !== 1 ? "18%" : "16%",
      className: "min-width-240",
    },
    {
      title:
        currency === "EUR"
          ? t("Label of revenue") + " (€)"
          : t("Label of revenue") + " (đ)",
      width: filterConditions.reportStatus !== 1 ? "17%" : "15%",
      dataIndex: "userNetRevenue",
      render: (text) => {
        const formattedNumber = Number(text.toFixed(3))
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return <span>{formattedNumber}</span>;
      },
      className: "min-width-160",
      align: "right",
    },
    {
      title:
        currency === "EUR"
          ? t("3S of revenue") + " (€)"
          : t("3S of revenue") + " (đ)",
      width: filterConditions.reportStatus !== 1 ? "14%" : "12%",
      dataIndex: "netRevenue3SInWeb",
      render: (text) => {
        const formattedNumber = Number(text.toFixed(3))
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return <span>{formattedNumber}</span>;
      },
      className: "min-width-120",
      align: "right",
    },
    {
      title: t("Status"),
      render: (record) => {
        return record.isPaid ? (
          <span style={{ color: "#2EC957" }}>{t("Paid")}</span>
        ) : (
          <span style={{ color: "#FF9A0D" }}>{t("Unpaid")}</span>
        );
      },
      align: "center",
      width: filterConditions.reportStatus !== 1 ? "13%" : "11%",
      className: "min-width-120",
    },
    {
      title: t("Reporting month"),
      dataIndex: "reportingMonth",
      render: (text) => moment(text).format("MM/YYYY"),
      align: "center",
      width: filterConditions.reportStatus !== 1 ? "14%" : "12%",
      className: "min-width-120",
    },
    {
      title: t("Payment time"),
      dataIndex: "paidDate",
      render: (text) => moment(text).format("DD/MM/YYYY HH:mm:ss"),
      align: "center",
      width: "12%",
      className: "min-width-120",
      hidden: filterConditions.reportStatus !== 1,
    },
  ].filter((column) => !column.hidden);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // Thêm checkbox vào table

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.isPaid,
    }),
  };

  const [open, setOpen] = useState([]);

  const handleToggle = () => {
    if (open.length) {
      setOpen([]);
    } else {
      setOpen([1]);
    }
  };

  const getData = useCallback(() => {
    authPostData({
      url: `api/v1/income?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading: setLoadingList,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setData(
            res.data.map((e, index) => ({
              ...e,
              key: e.saleReportId,
              no:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotalRevenue(res.totalRevenue);
          setTotal(res.paging.totalCount);
          setTotal3SRevenue(res.total3SRevenue);
          setTotalArtistsPaidRevenue(res.totalUserPaidRevenue);
          setTotalArtistsUnPaidRevenue(res.totalUserUnPaidRevenue);
        }
      },
      currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions, culture, currency]);

  useEffect(() => {
    if (filterConditions.startDate != null) {
      getData();
    }
  }, [getData, filterConditions, culture, currency]);

  const showModal = () => {
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      const filteredData = data.filter((item) =>
        selectedRowKeys.includes(item.saleReportId)
      );
      const trackReportingMonths = filteredData.map(
        ({ trackId, reportingMonth }) => ({ trackId, reportingMonth })
      );
      authPostData({
        url: `api/v1/income/pay?culture=${culture}`,
        method: "POST",
        payload: {
          ...filterConditions,
          trackReportingMonths,
        },
        setLoading: setLoadingPay,
        setImportLoading: () => {},
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            setVisible(true);
            setPaymentData(res.data);
          }
        },
        currency,
      });
    } else {
      authPostData({
        url: `api/v1/income/pay?culture=${culture}`,
        method: "POST",
        payload: {
          ...filterConditions,
        },
        setLoading: setLoadingPay,
        setImportLoading: () => {},
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            setVisible(true);
            setPaymentData(res.data);
          }
        },
        currency,
      });
    }
  };

  const handleOk = (values) => {
    setConfirmLoading(true);

    authPostData({
      url: `api/v1/income/pay-confirm?culture=${culture}`,
      method: "POST",
      payload: {
        transactionId: payId,
        payDate: moment(values.payDate).format("YYYY-MM-DDTHH:mm:ss"),
      },
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          notification.open({
            message: t("Success"),
            description: t("Paid successful"),
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
          setVisible(false);
          form.resetFields();
          setConfirmLoading(false);
          getData();
        }
      },
      currency,
    });
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (paymentData) {
      const {
        labelName,
        totalRevenue,
        totalUserRevenue,
        startDate,
        endDate,
        transactionId,
      } = paymentData;
      setLabelPay(labelName);
      setTotalPay(
        Number(totalUserRevenue.toFixed(3))
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      );
      setrealTotalPay(
        Number(totalRevenue.toFixed(3))
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      );
      setPayId(transactionId);
      const objStartDate = new Date(startDate);
      const objEndDate = new Date(endDate);

      const convertStartDate =
        typeof objStartDate === "object" &&
        `${moment(objStartDate).format("MM/YYYY")}`;

      const convertEndDate =
        typeof objEndDate === "object" &&
        `${moment(objEndDate).format("MM/YYYY")}`;
      setPayStartDate(convertStartDate);
      setPayEndDate(convertEndDate);
    }
  }, [paymentData]);

  useEffect(() => {
    handleToggle();
  }, []);

  const disabledDate = (current) => {
    return current && current > moment();
  };

  return (
    <>
      <PaymentFilter
        loadingList={loadingList}
        setFilterConditions={setFilterConditions}
      />
      <ContentStyled>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Revenue list")}</div>
              <span>
                {t("Status")} -
                {filterConditions && filterConditions.reportStatus
                  ? Number(filterConditions.reportStatus) === 3
                    ? t("All")
                    : Number(filterConditions.reportStatus) === 1
                    ? t("Paid")
                    : Number(filterConditions.reportStatus) === 2
                    ? t("Unpaid")
                    : t("All")
                  : t("All")}
              </span>

              {userData.isAdmin && (
                <Button
                  className="button-payment"
                  onClick={showModal}
                  loading={loadingPay}
                >
                  {t("Payment")}
                </Button>
              )}

              {visible && (
                <Modal
                  className="payment-modal"
                  title={t("Confirm pay")}
                  open={visible}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <Styled>
                    <Form
                      form={form}
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      onFinish={handleOk}
                    >
                      <p>
                        {t("Are you sure you want to pay")}{" "}
                        {currency === "EUR" ? (
                          <span>{realtotalPay} €</span>
                        ) : (
                          <span>{realtotalPay} đ</span>
                        )}{" "}
                        {t("[where")}{" "}
                        {currency === "EUR" ? (
                          <span>{totalPay} €</span>
                        ) : (
                          <span>{totalPay} đ</span>
                        )}{" "}
                        {t(
                          "is revenue for users and the rest is revenue for 3S] in month"
                        )}{" "}
                        <span>{payStartDate}</span> {t("for")}{" "}
                        <span>{labelPay}</span>?
                      </p>
                      <FormItem
                        label={t("Reporting month")}
                        name="payDate"
                        initialValue={moment(new Date())}
                      >
                        <DatePicker
                          showTime
                          format="YYYY-MM-DD HH:mm"
                          disabledDate={disabledDate}
                        />
                      </FormItem>
                      <div className="btn-action">
                        <Space key="update-album-thumb">
                          <Button htmlType="button" onClick={handleCancel}>
                            {t("Cancel")}
                          </Button>
                          <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                          >
                            {t("Agree")}
                          </Button>
                        </Space>
                      </div>
                    </Form>
                  </Styled>
                </Modal>
              )}
            </div>
            <div className="footer">
              {isAdmin && (
                <>
                  <div>
                    <span>{t("Total")}: </span>
                    {currency === "EUR" ? (
                      <span>
                        {totalRevenue &&
                          Number(totalRevenue.toFixed(3))
                            .toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                        €
                      </span>
                    ) : (
                      <span>
                        {totalRevenue &&
                          Number(totalRevenue.toFixed(3))
                            .toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                        đ
                      </span>
                    )}
                  </div>

                  <div>
                    <span>{t("3S Revenue:")} </span>
                    {currency === "EUR" ? (
                      <span>
                        {total3SRevenue &&
                          Number(total3SRevenue.toFixed(3))
                            .toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                        €
                      </span>
                    ) : (
                      <span>
                        {total3SRevenue &&
                          Number(total3SRevenue.toFixed(3))
                            .toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                        đ
                      </span>
                    )}
                  </div>
                </>
              )}

              <div>
                <span>{t("Paid (to Label):")} </span>
                {currency === "EUR" ? (
                  <span>
                    {totalArtistsPaidRevenue &&
                      Number(totalArtistsPaidRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    €
                  </span>
                ) : (
                  <span>
                    {totalArtistsPaidRevenue &&
                      Number(totalArtistsPaidRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    đ
                  </span>
                )}
              </div>

              <div>
                <span>{t("Unpaid for Labels:")} </span>
                {currency === "EUR" ? (
                  <span>
                    {totalArtistsUnPaidRevenue &&
                      Number(totalArtistsUnPaidRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    €
                  </span>
                ) : (
                  <span>
                    {totalArtistsUnPaidRevenue &&
                      Number(totalArtistsUnPaidRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    đ
                  </span>
                )}
              </div>
            </div>
            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  rowKey="key"
                  dataSource={data}
                  columns={columns}
                  totalData={total}
                  loading={loadingList}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                  rowSelection={rowSelection}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </ContentStyled>
    </>
  );
};

export default PaymentMain;

const ContentStyled = styled.div`
  .header span {
    font-size: 16px;
  }
  .content {
    .ant-table-row {
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #003366;
    padding: 16px;
    margin: 16px;
    margin-bottom: 0;

    div + div {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid #ccc;
    }

    span:nth-child(1) {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #fdfdfd;
    }

    span:nth-child(2) {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #fdfdfd;
      margin-left: 3px;
    }
  }
  .custom-checkbox {
    width: 2%;
  }

  thead .ant-checkbox-inner::after {
    top: 7px !important;
    left: 7px !important;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    display: none;
  }

  @media (max-width: 980px) {
    .header span {
      font-size: 14px;
    }
  }
`;

const Styled = styled.div`
  .btn-action {
    display: flex;
    justify-content: end;
    padding: 20px 0;
  }
`;
