import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  handlePagination,
  mapLanguageToCulture,
  disabledDateMonth,
  mapCurrencyToCulture,
} from "@utils/function";
import { authGetData, authPostData, downloadFile } from "@utils/request";
import TableComponent from "@components/TableComponent";
import SelectionLabels from "@components/SelectLabels";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeEn from "antd/es/date-picker/locale/en_US";

const SalesReportMain = () => {
  const { t, i18n } = useTranslation();
  const culture = mapLanguageToCulture();
  const currency = mapCurrencyToCulture();
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGESIZE,
  });

  const [load, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [state, setState] = useState({ loading: false });
  const [dataRevenue, setDataRevenue] = useState([]);
  const [xTime, setXTime] = useState();
  const [total, setTotal] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [totalUserPaidRevenue, setTotalUserPaidRevenue] = useState();
  const [total3SRevenue, setTotal3SRevenue] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [environment, setEnvironment] = useState();

  const { loading } = state;

  const columns = [
    {
      title: t("No."),
      dataIndex: "no",
      align: "center",
      width: "4%",
    },
    {
      title: t("Labels"),
      dataIndex: "labelName",
      align: "center",
      width: "14%",
      className: "min-width-120",
    },
    {
      title: t("Artist"),
      dataIndex: "artistNames",
      align: "center",
      width: "20%",
      className: "min-width-240",
    },
    {
      title: t("Revenue configuration (Artist/3S)"),
      dataIndex: "shareRate",
      render: (shareRate) => {
        const [numerator, denominator] = shareRate.split("/");
        const formattedShareRate = `${parseFloat(numerator).toFixed(
          0
        )}/${parseFloat(denominator).toFixed(0)}`;
        return formattedShareRate;
      },
      align: "center",
      width: "14%",
      className: "min-width-130",
    },
    {
      title:
        currency === "EUR"
          ? t("Total Revenue") + " (€)"
          : t("Total Revenue") + " (đ)",
      dataIndex: "totalRevenue",
      render: (text) => {
        const formattedNumber = Number(text).toLocaleString("en-EN", {
          useGrouping: true,
          maximumFractionDigits: 3,
        });

        return <span>{formattedNumber}</span>;
      },
      align: "center",
      width: "11%",
      className: "min-width-100",
    },
    {
      title: currency === "EUR" ? t("Artist") + " (€)" : t("Artist") + " (đ)",
      dataIndex: "userRevenue",
      render: (text) => {
        const formattedNumber = Number(text).toLocaleString("en-EN", {
          useGrouping: true,
          maximumFractionDigits: 3,
        });

        return <span>{formattedNumber}</span>;
      },
      align: "center",
      width: "10%",
    },
    {
      title:
        currency === "EUR" ? t("3S Media") + " (€)" : t("3S Media") + " (đ)",
      dataIndex: "sRevenue",
      render: (text) => {
        const formattedNumber = Number(text).toLocaleString("en-EN", {
          useGrouping: true,
          maximumFractionDigits: 3,
        });

        return <span>{formattedNumber}</span>;
      },
      align: "center",
      width: "10%",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      render: (text) => {
        if (text === "Đã Thanh Toán" || text === "Paid") {
          return <span style={{ color: "#00b050" }}>{text}</span>;
        }
        return <span style={{ color: "#ea99b3" }}>{text}</span>;
      },
      align: "center",
      width: "12%",
    },
  ];

  useEffect(() => {
    authGetData({
      url: `api/v1/update/reportingmonth`,
      setLoading: () => {},
      onSuccess: (res) => {
        if (res) {
          setXTime(res);
          setFilterConditions({
            startDate: moment(res).subtract(1, "year").format("MM/YYYY"),
            endDate: moment(res).format("MM/YYYY"),
            pageIndex: 1,
            pageSize: DEFAULT_PAGESIZE,
            status: 1,
          });
        }
      },
    });
  }, []);
  useEffect(() => {
    const endDate = moment(xTime);
    const startDate = moment(xTime).subtract(1, "year");

    form.setFieldsValue({
      time: [startDate, endDate],
    });
  }, [xTime, form]);

  const onFinish = (values) => {
    setFilterConditions({
      ...values,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
      startDate: values?.time[0]
        ? moment(values?.time[0]).format("MM/YYYY")
        : null,
      endDate: values?.time[1]
        ? moment(values?.time[1]).format("MM/YYYY")
        : null,
      stupidLabelName:
        values?.stupidLabelName && values?.stupidLabelName.length > 0
          ? values?.stupidLabelName
          : null,
      status: values?.status ? values?.status : 1,
    });
    setSelectedRowKeys([]);
  };

  const getData = useCallback(() => {
    authPostData({
      url: `api/v1/artist/show-artist-income?culture=${culture}`,
      method: "POST",
      payload: {
        ...filterConditions,
      },
      setLoading: setLoadingList,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setDataRevenue(
            res.data.map((e, index) => ({
              ...e,
              key: e.id,
              no:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotal(res.paging.totalCount);
          setTotalRevenue(res.totalRevenue);
          setTotal3SRevenue(res.total3SRevenue);
          setTotalUserPaidRevenue(res.totalUserPaidRevenue);
        }
      },
      currency,
    });
  }, [filterConditions, culture, currency]);

  useEffect(() => {
    if (filterConditions.startDate) {
      getData();
    }
  }, [getData, filterConditions, currency]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, filterConditions, setFilterConditions);
  };

  useEffect(() => {
    authGetData({
      url: `api/v1/update/environment`,
      setLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          setEnvironment(res.data);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = () => {
    const endTime = moment(xTime);
    const startTime = moment(xTime).subtract(1, "year");
    const endDate = moment(xTime).format("MM/YYYY");
    const startDate = moment(xTime).subtract(1, "year").format("MM/YYYY");

    form.setFieldsValue({
      stupidArtist: "",
      time: [startTime, endTime],
      status: 1,
    });
    form.resetFields(["stupidLabelName"]);
    setFilterConditions({
      startDate,
      endDate,
      status: 1,
      pageIndex: 1,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  // Xử lý xuất excel

  const handleExportExcel = () => {
    const filteredData = dataRevenue.filter((item) =>
      selectedRowKeys.includes(item.id)
    );
    const artistNames = filteredData.map((item) => {
      return item.artistNames;
    });
    const stupidLabelName = filteredData.map((item) => {
      return item.labelId;
    });
    const startDate = filterConditions.startDate;
    const endDate = filterConditions.endDate;

    authPostData({
      url: `api/v1/artist/extract-income?culture=${culture}`,
      method: "POST",
      payload: {
        artistNames,
        stupidLabelName,
        startDate,
        endDate,
      },
      setLoading: setLoadingList,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res?.statusCode === 200) {
          const fileData = res?.data;
          const nameoffile = processDateRange();
          const endpoint =
            environment + "api/v1/artist/export-file/" + fileData;
          downloadFile({ endpoint, setLoading, nameoffile });
          setSelectedRowKeys([]);
        }
      },
      currency,
    });
  };

  // xử lý lấy tên file

  const processDateRange = () => {
    const startYear = filterConditions.startDate.substring(3);
    const endYear = filterConditions.startDate.substring(3);
    const currentDate = moment(new Date()).format("DD/MM/YYYY");

    if (startYear === endYear) {
      if (
        filterConditions.startDate === `01/${startYear}` &&
        filterConditions.endDate === `12/${endYear}`
      ) {
        return (
          "Doanh thu phát hành nhạc số năm" +
          startYear +
          "_" +
          currentDate +
          ".xlsx"
        );
      } else {
        const output = `${filterConditions.startDate}~${filterConditions.endDate}`;
        return (
          "Doanh thu phát hành nhạc số tháng " +
          output +
          "_" +
          currentDate +
          ".xlsx"
        );
      }
    } else {
      const output = `${filterConditions.startDate}~${filterConditions.endDate}`;
      return (
        "Doanh thu phát hành nhạc số tháng " +
        output +
        "_" +
        currentDate +
        ".xlsx"
      );
    }
  };

  // Thêm checkbox vào table

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === "Đã Thanh Toán" || record.status === "Paid",
      status: record.status,
    }),
  };

  return (
    <Spin spinning={load}>
      <ContentStyled>
        <div className="select-option">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={[20, 20]}>
              <Col xl={7} lg={12} md={24}>
                <FormItem name="stupidArtist">
                  <Input placeholder={t("Seach by artist name")} />
                </FormItem>
              </Col>
              <Col xl={9} lg={12} md={24}>
                <FormItem label={t("Reporting month")} name="time">
                  <RangePicker
                    locale={i18n.language === "vi" ? localeVi : localeEn}
                    format="MM/YYYY"
                    picker="month"
                    disabledDate={disabledDateMonth}
                  />
                </FormItem>
              </Col>
              <Col xl={8} lg={12} md={12}>
                <Form.Item label={t("Labels")} name="stupidLabelName">
                  <SelectionLabels
                    url={`api/v1/overview/filter/labelnames`}
                    formKey="stupidLabelName"
                    form={form}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              <Col xl={7} lg={12} md={12}>
                <Form.Item label={t("Status")} name="status">
                  <Select
                    showSearch
                    allowClear
                    placeholder={t("Status")}
                    defaultValue={1}
                  >
                    <Select.Option value={3}>{t("All")}</Select.Option>
                    <Select.Option value={1}>{t("Unpaid")}</Select.Option>
                    <Select.Option value={2}>{t("Paid")}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={16} lg={24} md={24}>
                <div className="action-buttons">
                  <Button onClick={handleReset}>{t("Clear all")}</Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("Search")}
                  </Button>
                  <Button
                    className="btn-excel"
                    disabled={selectedRowKeys.length === 0 ? true : false}
                    onClick={handleExportExcel}
                  >
                    {t("Excel output")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-data--wrap">
          <div className="table-data--main">
            <div className="header">
              <div className="title">{t("Revenue report")}</div>
            </div>

            <div className="footer">
              <div>
                <span>{t("Total Revenue")}: </span>
                {currency === "EUR" ? (
                  <span>
                    {totalRevenue &&
                      Number(totalRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    €
                  </span>
                ) : (
                  <span>
                    {totalRevenue &&
                      Number(totalRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    đ
                  </span>
                )}
              </div>
              <div>
                <span>{t("Label 's Revenue")}: </span>
                {currency === "EUR" ? (
                  <span>
                    {totalUserPaidRevenue &&
                      Number(totalUserPaidRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    €
                  </span>
                ) : (
                  <span>
                    {totalUserPaidRevenue &&
                      Number(totalUserPaidRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    đ
                  </span>
                )}
              </div>
              <div>
                <span>{t("3S Revenue:")} </span>
                {currency === "EUR" ? (
                  <span>
                    {total3SRevenue &&
                      Number(total3SRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    €
                  </span>
                ) : (
                  <span>
                    {total3SRevenue &&
                      Number(total3SRevenue.toFixed(3))
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
                    đ
                  </span>
                )}
              </div>
            </div>

            <div className="content">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty description={t("No Records Found")} />
                )}
              >
                <TableComponent
                  rowKey="key"
                  dataSource={dataRevenue}
                  columns={columns}
                  totalData={total}
                  loading={loadingList}
                  onChangePagination={onChangePagination}
                  pagination={filterConditions}
                  rowSelection={rowSelection}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </ContentStyled>
    </Spin>
  );
};

export default SalesReportMain;

const ContentStyled = styled.div`
  .select-option {
    background-color: #fff;
    margin: 0 20px;
    padding: 20px 16px;
    border-radius: 10px;
  }
  .action-buttons {
    justify-content: start;
    margin-top: 0;
  }
  .ant-row {
    align-items: center;
  }
  .ant-form-item {
    margin: 0 !important;
  }

  .footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #003366;
    padding: 16px;
    margin: 16px;
    margin-bottom: 0;

    div + div {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid #ccc;
    }

    span:nth-child(1) {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #fdfdfd;
    }

    span:nth-child(2) {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #fdfdfd;
      margin-left: 3px;
    }
  }

  .footer div {
    width: 33%;
  }

  .btn-excel {
    background-color: #003366;
    color: #fff;
  }

  thead .ant-checkbox-inner::after {
    top: 7px !important;
    left: 7px !important;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    display: none;
  }

  @media (max-width: 1199px) {
    .action-buttons {
      justify-content: end;
    }
  }
`;
